body {
  background-color: white;
}
.App {
  padding: 0;
  display: flex;
  flex-direction: row;
}

.sidebar ul {
  list-style: none;
  padding:0;
  margin:0;
}

.sidebar li {
  padding: 20px 0px 20px 20px;
  color: white;
}
.sidebar li.link.header {
  padding-left: 20px;
}
.sidebar li.link {
  padding-left: 36px;
  color: white;
}
.content {
  padding-left: 20px;
}
.catalyst-header {
  background-color: #222935;
  color: white;
  font-size: 18px;
  padding: 18px 0px 20px 20px;
  width: 100%;
  min-width: 100%;
  min-height: 20px;
  max-height: 20px;
}

.input-denylist {
  font-size: 18px;
  border-radius: 5px;
  padding: 5px 10px 5px 10px;
  outline: none;
  width: 300px;
  margin: 0px;
  border: 1px solid #999;
}
.sidebar li.active {
  cursor: pointer;
  background-color: #3a4354;
}
.sidebar li.link:hover {
  cursor: pointer;
  background-color: #3c4b64;
}

.main {
  min-height: 100vh;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
}

.sidebar {
  max-width: 200px;
  min-width: 200px;
  background-color: #222935;
  flex-grow: 1;
}